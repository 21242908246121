@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.portfolio-section {
    padding: 50px 100px;
    font-family: 'Open Sans', sans-serif;
}

.portfolio-section .portfolio-container h1 {
    font-size: 50px;
    margin-bottom: 30px;
}

.text-portfolio-type {
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .portfolio-section {
        padding: 50px 20px;
    }

    .portfolio-section .portfolio-container h1 {
        font-size: 40px;
    }
}
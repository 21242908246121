@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');

:root {
    --footer-top-border-color: #eee;
}

@media (prefers-color-scheme: dark) {
    :root {
        --footer-top-border-color: #333;
    }
}

.footer-body {
    padding: 30px 0;
    border-top: 2px solid var(--footer-top-border-color);
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
}

.footer-body .row {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
}

.row .row-left{
    width: 30%;
}
.row .row-right {
    width: 70%;
    text-align: end;
}

.row .row-left,
.row .row-right {
    padding: 10px 100px;
}

.developer {
    margin: 0 120px;
    font-size: .8em;
}

.developer a {
    text-decoration: none;
    color: var(--body-link-color);
}

.developer a:hover {
    text-decoration: underline;
    color: var(--body-link-hover-color);
}

.footer-icon {
    margin: 0 8px;
    text-decoration: none;
}

.footer-icon .text {
    color: var(--body-link-color);
}

.footer-icon .text:hover {
    text-decoration: underline;
    color: var(--body-link-hover-color);
}

@media all and (max-width:849px){
    .footer-body {
        margin-top: 0;
    }

    .footer-body .row {
        flex-direction: column;
        justify-content: center;
    }

    .footer-body .row .row-left,
    .footer-body .row .row-right {
        display: block;
        margin: auto;
        text-align: center;
        padding: 5px;
    }

    .developer {
        text-align: center;
    }
}

@media all and (max-width: 431px) {
    .footer-body {
        font-size: .7em;
    }
}

@media all and (max-width: 338px) {
    .footer-body {
        font-size: .6em;
    }
}
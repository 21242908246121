@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.contact-section {
    position: relative;
    padding: 10px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
}

.contact-section .content {
    max-width: 800px;
    text-align: center;
}

.contact-section .content h1 {
    font-size: 50px;
}

.contact-section .content p {
    font-weight: 300;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.container .contactInfo {
    width: 50%;
    margin-top: 50px;
    border-right: 2px solid #eee;
}

.container .contactInfo .box {
    position: relative;
    padding: 5px 0;
}

.container .contactInfo .box .text {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}

.container .contactInfo .box .text small, p {
    word-wrap: break-word;
}

.container .contactInfo .box .text small {
    font-weight: 300;
    text-align: right;
    padding-right: 15px;
    max-width: fit-content;
}

.contactme-text {
    margin-bottom: 20px;
}

.contact-form-info-link,
.about-link {
    text-decoration: none;
    color: var(--body-link-color);
}

.contact-form-info-link:hover,
.about-link:hover {
    text-decoration: underline;
    color: var(--body-link-hover-color);
}

.contactForm {
    width: 40%;
    padding: 40px;
    transition:.5s;
}

.contactForm h2 {
    font-size: 30px;
    font-weight: 500;
}

.contactForm .inputBox {
    width: 100%;
    margin-top: 10px;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    outline: none;
    background-color: #ddd;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
    resize: vertical;
}

.contactForm .inputBox input:focus,
.contactForm .inputBox textarea:focus{
    border-bottom: 2px solid #333;
}
.contactForm .submit-btn {
    width: 100%;
    margin: 0 auto;
}

@media all and (max-width:991px) {
    .contact-section {
        padding: 50px 50px;
    }

    .container {
        flex-direction: column;
    }

    .container .contactInfo {
        margin-bottom: 40px;
        margin-top:0;
        padding-bottom: 40px;
        width: 100%;
        border-right: 0;
        border-bottom: 2px solid #eee;
    }

    .container .contactForm {
        width: 100%;
        padding-left:0;
    }

    .container .contactInfo .box .text small {
        width: 25%;
        font-weight: 300;
    }

    .contact-section .content h1 {
        font-size: 40px;
    }
}
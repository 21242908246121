:root {
  --body-bg-color: #fff;
  --body-text-color: #333;
  --body-link-color: #333;
  --body-link-hover-color: #222;
  --scrollbar-track-color: #fff;
  --scrollbar-thumb-color: #333;
  --scrollbar-thumb-hover-color: #222;
  --btn-bg-color: #ddd;
  --btn-hover-bg-color: #333;
  --btn-text-color: #000;
  --btn-hover-text-color: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
      --body-bg-color: #063b53;
      --body-text-color: #fff;
      --body-link-color: #ddd;
      --body-link-hover-color: #fff;
      --scrollbar-track-color: #063b53;
      --btn-bg-color: #ddd;
      --btn-hover-bg-color: #9c9c9c;
      --btn-text-color: #000;
      --btn-hover-text-color: #000;
  }
}

* {
  margin: 0;
  padding: 0;

  /* Highlight color */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow-x: hidden;
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Selection */
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #333;
}

::selection {
  color: white;
  background: #333;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

/* Button */
.btn {
  background-color: var(--btn-bg-color);
  color: var(--btn-text-color);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 2px;
}

.btn:hover {
  background-color: var(--btn-hover-bg-color);
  color: var(--btn-hover-text-color);
}

.btn-sm {
  padding: 5px 5px;
}

/* Grey background badge */
.badge {
  font-size: 10px;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
    --certificate-field-hover-bg-color: #f2f2f2;
}

@media (prefers-color-scheme: dark) {
    :root {
        --certificate-field-hover-bg-color: #a7a7a721;
    }
}

.certificates-section {
    padding: 50px 100px;
    font-family: 'Open Sans', sans-serif;
}

.certificates-section .certificates-container h1 {
    font-size: 50px;
    margin-bottom: 30px;
}

.text-certificate-year {
    margin-top: 20px;
    cursor: default;
}

.text-certificate-year span {
    display: inline-block;
    vertical-align: middle;
}

.cert-spinner {
    text-align: center;
    margin-top: 20px;
}

.cert-field {
    padding: 5px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: default;
    transition: 500ms;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.cert-field:hover {
    background-color: var(--certificate-field-hover-bg-color);
    border: 1px solid #afafaf;
}

@media screen and (max-width: 768px) {
    .certificates-section {
        padding: 50px 20px;
    }

    .certificates-section .certificates-container h1 {
        font-size: 40px;
    }
}
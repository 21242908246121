@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap');

:root {
    --navbar-text-color: #490000;
    --navbar-text-active-color: #000;
    --navbar-bg-color: #fff;
    --hamburger-menu-color: #000;
    --dropdown-item-background-color: #eee;
    --dropdown-item-hover-color: #ddd;
    --nav-list-hover-color: #cfcfcf;
}

@media (prefers-color-scheme: dark) {
    :root {
        --navbar-text-color: #d6d6d6;
        --navbar-text-active-color: #fff;
        --navbar-bg-color: #04293A;
        --hamburger-menu-color: #fff;
        --nav-list-hover-color: #032230;
    }
  }

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 35px;
    font-family: 'Mouse Memoirs', sans-serif;
    padding: 10px 0;
    background-color: var(--navbar-bg-color);
}

.nav-list {
    list-style-type: none;
    display: flex;
    align-items: center;
}

.nav-list .list-item {
    display: inline-block;
    padding: 20px 10px;
}

.navbar a {
    text-decoration: none;
}

.navbar-text.right:hover {
    transform: rotate(-2deg) scale(1.05) translate(-4px, -7px);
    transition: 0.1s;
}
.navbar-text.left:hover {
    transform: rotate(2deg) scale(1.05) translate(4px, -7px);
    transition: 0.1s;
}

.navbar-text.active:hover {
    transform: none;
}

.navbar-text {
    color: var(--navbar-text-color);
    display:inline-block;
}

.navbar-text.active {
    color: var(--navbar-text-active-color);
    text-shadow: #919191 0 0 10px;
}

.navbar-logo {
    border-radius: 50%;
    height: 120px;
    width: auto;
    animation: logo-entry 1s ease-in-out 1s;
}

@keyframes logo-entry {
    0%{transform: scale(0.96);}
    20%{transform: scale(1.1);}
    40%{transform: scale(0.58);}
    60%{transform: scale(1.05);}
    80%{transform: scale(1.01);}
    100%{transform: scale(1);}
}

.menu {
    padding: 2px;
    cursor: pointer;
    display: none;
}

.menu-line {
    width: 30px;
    height: 4px;
    background-color: var(--hamburger-menu-color);
    margin-bottom: 6px;
    border-radius: 5px;
}

.menu:active .menu-line {
    -webkit-animation: hamburger-anim 400ms ease-in-out;
    animation: hamburger-anim 400ms ease-in-out alternate forwards;
}

@keyframes hamburger-anim {
    0%{
        transform: scale(0.96);
    }
    20%{
        transform: scale(1.1);
    }
    40%{
        transform: scale(0.58); /* 0.98 for small spring look */
    }
    60%{
        transform: scale(1.05);
    }
    80%{
        transform: scale(1.01);
    }
    100%{
        transform: scale(1);
    }
}

/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-menu {
    display: inline-block;
    position: absolute;
    min-width: 160px;
    z-index: 1;
    font-size: initial !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.dropdown-menu a {
    text-decoration: none;
    color: inherit;
}

.dropdown-item {
    background-color: var(--btn-bg-color);
    display: block;
    padding: 10px 10px;
    cursor: pointer;
    color: var(--btn-text-color);
}

.arrow {
    border: solid var(--btn-text-color);
    border-width: 0 3px 3px 0;
    margin-left:5px;
    display: inline-block;
    padding: 3px;
  }
  
  .btn:hover .arrow {
    border-color: var(--body-hover-text-color);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

.dropdown-item:hover {
    background-color: #ccc;
    color: #000;
}

.dropdown.hidden {
    display:none;
}

.blog-link-container {
    position: fixed;
    top: 15%;
    z-index: 1;
    padding: 10px;
    font-size: 18px;
    text-decoration: none;
    color: #000;
    box-shadow: 0 0 1rem 0 #0000001a;
    border-radius: 0 10px 10px 0;
    background-color: #ffffff26;
    color: var(--body-text-color);
    backdrop-filter: blur(5px);
    font-family: "Comic sans ms", sans-serif;
    border: 1px solid transparent;
}

.blog-link-container:hover {
    background-color: #89e2d626;
    border: 1px solid #89e2d6;
    border-left: 1px solid transparent;
    box-shadow: 0 0 1rem 0 #89e2d6;
    color: var(--body-text-color);
}

@media all and (max-width: 930px) {
    .nav-list{
        display: block;
    }

    .navbar {
        flex-direction: column;
    }
    
    .navbar-logo {
        height: 80px;
    }
    
    .menu {
        display:block;
        position: absolute;
        top: 50px;
        right: 20px;
    }
    
    .nav-list .list-item {
        display: block;
    }

    .nav-list .list-item:hover {
        background-color: var(--nav-list-hover-color);
    }

    .nav-list .list-item:hover a {
        transform: none;
    }
    
    .nav-list {
        width: 100%;
        text-align: right;
        margin-top: 30px;
        transition: 0.5s;
        animation-name: navbar-slide-in;
        animation-duration: .5s;
        animation-iteration-count: 1;
    }
    @keyframes navbar-slide-in {
        0% {
            transform: translateY(-100%);
            opacity: 0;
        }
        30%{
            opacity: 0;
        }
        100% {
            transform: translateY(0);opacity:1;
        }
    }

    .nav-logo-parent {
        width: 100%;
    }

    .nav-logo-parent img {
        margin-left: 30px;
    }

    .nav-list-hidden {
        display: none;
    }

    .dropdown-menu {
        right: 0;
    }
}
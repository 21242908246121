:root {
    --card-text-color: #000;
}

@media (prefers-color-scheme: dark) {
    :root {
        --card-text-color: #ddd;
    }
}

.card {
    width: 30%;
    text-align: center;
    display: inline-block;
    position: relative;
    transition: .1s;
    margin: 8px 8px;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
    background-color: #8a8a8a07;
    border: #8a8a8a1e 1px solid;
}

a .card {
    cursor: pointer;
}

.card img {
    max-height: 200px;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    display: block;
    margin: auto;
    max-width: 100%;
}

.card-text {
    margin: auto;
    color: var(--card-text-color);
    font-size: 25px;
    font-weight: 400;
    text-decoration: none;
}

.card-extra-info {
    font-weight: 600;
    border-radius: 0 0 5px 5px;
}

.card-badge-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: #8a8a8a1e 1px solid;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fd5634;
    box-shadow: inset 0 5px 5px 5px #3333331e;
}

.card:hover {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media all and (max-width:1335px){
    .card {
        width: 28%;
    }

    .card-text {
        font-size: 20px;
    }
}

@media all and (max-width:969px){
    .card {
        width: 44%;
    }
}

@media all and (max-width:843px){
    .card {
        width: 43%;
    }
}

@media all and (max-width:592px){
    .card {
        width: 42%;
    }
}

@media all and (max-width:550px){
    .card {
        width: 90%;
        max-height: 300px;
        height: 100%;
    }
}
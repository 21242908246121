:root {
    --spinner-outer-width: 40px;
    --spinner-inner-width: 20px;
    --spinner-border-width: 5px;
    --spinner-container-width: 50px; /* --spinner-outer-width + (2 x --spinner-border-width)*/
    --spinner-outer-color: #333;
    --spinner-inner-color: #757575;
}

@media (prefers-color-scheme: dark) {
    :root{
        --spinner-outer-color: #fff;
        --spinner-inner-color: #afafaf;
    }
}

.spinner-container {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 50px;
}

.spinner-outer {
    animation: 1s linear infinite spinner-outer;
    border: solid var(--spinner-border-width) var(--spinner-outer-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    content: "";
    height: var(--spinner-outer-width);
    width: var(--spinner-outer-width);
    position: absolute;
}

.spinner-inner {
    animation: 1s linear infinite spinner-inner;
    border: solid var(--spinner-border-width) var(--spinner-inner-color);
    border-top-color: transparent;
    border-radius: 50%;
    content: "";
    height: var(--spinner-inner-width);
    width: var(--spinner-inner-width);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spinner-outer {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(20deg);
    }
    40% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-inner {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    30% {
        transform: translate(-50%, -50%) rotate(-20deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.portfolioDetailSection {
    padding: 50px 100px;
    font-family: 'Open Sans', sans-serif;
}

.portfolio-detail-spinner {
    text-align: center;
}

.portfolioImg{
    text-align: center;
    margin-bottom: 40px;
}

.portfolioImg img {
    border-radius: 5px;
    width: 80%;
}

.portfolio-title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 50px;
    text-align: center;
}

.portfolio-desc {
    margin-bottom: 50px;
    padding: 10px;
}

.portfolio-links {
    text-align: center;
}

.portfolio-detail-icon {
    margin: 0 20px;
    font-size: 50px;
}

.portfolio-detail-icon:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 720px) {
    .portfolioDetailSection {
        padding: 50px 20px;
    }

    .portfolioImg img {
        width: 80%;
    }

    .portfolio-title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .portfolio-detail-icon {
        font-size: 30px;
        margin: 0px 10px;
    }
}
@media screen and (max-width: 580px) {
    .portfolio-title {
        font-size: 20px;
    }
}
@media screen and (max-width: 380px) {
    .portfolio-title {
        font-size: 15px;
    }
}
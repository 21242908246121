@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.about-section {
    padding: 50px 50px;
    font-family: 'Open Sans', sans-serif;
}

.about-section h1 {
    font-size: 50px;
    margin: 0 0 50px 50px;
}

.aboutme-image {
    float: left;
    margin: 0px 50px 0 0;
    object-fit: cover;
    width: 300px;
    transition: .1s; /*Only for a nice smooth effect when resizing window*/
}

.about-section p {
    margin: 20px 0;
}

.about-section a {
    color: var(--body-link-color);
    text-decoration: none;
}

.about-section a:hover {
    color: var(--body-link-hover-color);
    text-decoration: underline;
}

.icon-container {
    text-align: center;
    margin-top: 50px;
}

.aboutme-icon {
    font-size: 50px;
    margin: 0px 20px;
}

.aboutme-icon:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .about-section {
        padding: 50px 20px;
    }

    .about-section h1 {
        font-size: 40px;
        margin-left: 0;
    }
}

@media all and (max-width: 710px) {
    .aboutme-image {
        width: 150px;
    }
}

@media all and (max-width: 450px) {
    .about-section {
        padding: 50px 10px;
        text-align: center;
    }

    .aboutme-image {
        display:block !important;
        width: 50%;
        margin: auto;
        float: none;
    }

    .aboutme-icon {
        font-size: 30px;
        margin: 0px 10px;
    }
}